import React from "react"

import * as styles from "./styles"

const LinksContainer = ({url, label, external, discount, code, children}) => 
{
  const hasBrand = url && label;
  const hasDiscount = discount && code;

  return (
    <div css={styles.linksContainer}>
      {(hasBrand || hasDiscount) && (<div css={styles.linkHeader}>
        {hasBrand && (<h3><a css={styles.link} href={url} target={external ? "_blank" : "_self"} rel="noreferrer">{label}</a></h3>)    }
        {hasDiscount && (
          <div css={styles.discount}>
                <p>{discount}</p>
                <p>{code}</p>
            </div>
        )}
      </div>)}
      <div css={styles.linksGrid}>
        {children}
      </div>
    </div>
)}

export default LinksContainer