import React from "react"
import Img from 'gatsby-image';

import * as styles from "./styles"

const LinkItem = ({image, url, external, children}) => 
{
    return (
    <div css={styles.itemContainer}>
        <a css={styles.itemLink} href={url} target={external ? "_blank" : "_self"} rel="noreferrer">
            <Img fluid={image} />
            <p>{children}</p>
        </a>
    </div>
)}

export default LinkItem