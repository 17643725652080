import React from "react"

import * as styles from "./styles"

const SingleLinkContainer = ({url, label, external, discount, code, children}) => 
{
    return (
    <div css={styles.container}>
        <div css={styles.linkHeader}>
            <h3><a css={styles.link} href={url} target={external ? "_blank" : "_self"} rel="noreferrer">{label}</a></h3>    
            {(discount || code) && (
                <div css={styles.discount}>
                    <p>{discount}</p>
                    <p>{code}</p>
                </div>
            )}
        </div>
        {children}
    </div>
)}

export default SingleLinkContainer