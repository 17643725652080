import { css } from '@emotion/core';

export const infoList = css`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  
  li {
    list-style-type: none;
  }
  
  @media(max-width: 480px) {
    align-self: center;
  }
`

export const headerContainer = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 24px;

  @media(max-width: 1280px) {
    grid-template-columns: 2fr 1fr;
  }

  @media(max-width: 768px) {
    display: block;
  }
`

export const header = css`
  display: grid;
  margin-bottom: 8px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  .gatsby-image-wrapper {
    max-width: 200px;
    border-radius: 2px;
  }

  @media(max-width: 768px) {
    grid-template-columns: 1fr 2fr;
  }
`

export const linksContainer = css`
  margin: 0;
`

export const doubleLinkContainer = css`
  max-width: 848px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 16px;
`

export const disclaimer = css`
  margin: 32px 0 24px;
  font-size: 12px;
  line-height: 1.15;
`