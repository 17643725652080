import { css } from '@emotion/core';

export const itemContainer = css`
  max-width: 200px;

  @media(max-width: 768px) {
    display: inline-block;
    width: 100%;
    min-width: 130px;

    &:last-child {
      margin: 0 !important;
    }
  }
`

export const itemLink = css`
  display: inline-block;
  width: 100%;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  background-color: #2f2f2f;
  background-image: none;

  .gatsby-image-wrapper {
    margin-bottom: 8px;
    border-radius: 2px;
  }

  p {
    line-height: 1.2;
  }

  @media(max-width: 768px) {
    font-size: 16px;
    white-space: normal;

    &::after {
      content: none;
    }

    p::after {
        content: '›';
        display: inline;
        margin-left: 4px;
    }
  }

  @media (prefers-color-scheme: dark) {
    background-color: #dcdcdc;
    background-image: none;
  }
`