import React from "react"
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerLinkContainer from "../components/bannerLinkContainer"
import SingleLinkContainer from "../components/singleLinkContainer"
import LinkItem from "../components/linkItem"
import LinkArticle from "../components/linkArticle"
import LinksContainer from "../components/linksContainer"
import SectionTitle from "../components/sectionTitle"

import * as styles from "../styles/links.css"


const LinksPage = props => {
  return (
    <Layout professional={false} >
        <SEO title="Links - Kevin Ng" />
        <SectionTitle>About</SectionTitle>
        <div css={styles.headerContainer}>
          <div css={styles.header}>
              <Img fluid={props.data.profile.childImageSharp.fluid} />
              <ul css={styles.infoList}>
                  <li><span role="img" aria-label="Photos">📱</span> Tech + Design + Lifestyle</li>
                  <li><span role="img" aria-label="Location">🇨🇦</span> Toronto</li>
              </ul>
          </div>
          <div css={styles.linksContainer}>
            {/* <BannerLinkContainer url="http://ws.cash/invite/$kng" label="Get $25 with Wealthsimple Cash" external /> */}
            <BannerLinkContainer url="https://www.buymeacoffee.com/KevinNg" label="Buy me a coffee?" external />
            {/* <BannerLinkContainer url="https://www.makerstations.io/kevin-ng-developer-setup/" label="Maker Stations Interview" external/> */}
          </div>
        </div>
        <SectionTitle>My Gear</SectionTitle>
        <LinksContainer url="https://kevinng.ca/go/orbitkey" label="Orbitkey" discount="10% OFF" code="KEVINNG10D" external>
          <LinkArticle image={props.data.orbitkeyKeyOrganiser.childImageSharp.fluid} url="https://kevinng.ca/go/orbitkey-key-organiser" external>Key Organiser</LinkArticle>
          <LinkArticle image={props.data.orbitkeyDeskMat.childImageSharp.fluid} url="https://kevinng.ca/go/orbitkey-desk-mat" external>Desk Mat</LinkArticle>
          <LinkArticle image={props.data.orbitkeyRing.childImageSharp.fluid} url="https://kevinng.ca/go/orbitkey-ring" external>Ring</LinkArticle>
        </LinksContainer>
        <LinksContainer url="https://kevinng.ca/go/nomad" label="Nomad" external>
          <LinkArticle image={props.data.nomadCases.childImageSharp.fluid} url="https://kevinng.ca/go/nomad-cases" external>iPhone 14 Series Cases</LinkArticle>
          <LinkArticle image={props.data.nomadHub.childImageSharp.fluid} url="https://kevinng.ca/go/nomad-base-station-hub" external>Base Station Hub</LinkArticle>
          <LinkArticle image={props.data.nomadMini.childImageSharp.fluid} url="https://kevinng.ca/go/nomad-base-station-mini" external>Base Station Mini</LinkArticle>
          {/* <LinkArticle image={props.data.nomadSleeve.childImageSharp.fluid} url="https://kevinng.ca/go/nomad-leather-sleeve" external>Rugged Leather Sleeve</LinkArticle> */}
          <LinkArticle image={props.data.nomadWallet.childImageSharp.fluid} url="https://kevinng.ca/go/nomad-card-wallet-plus" external>Card Plus Wallet</LinkArticle>
        </LinksContainer>
        <LinksContainer>
          <SingleLinkContainer label="Secretlab" url="https://kevinng.ca/go/secretlab" external>
            <LinkItem image={props.data.secretlab.childImageSharp.fluid} url="https://kevinng.ca/go/secretlab-omega" external>Secretlab OMEGA gaming chair</LinkItem>
          </SingleLinkContainer>
          <SingleLinkContainer label="NuPhy" url="https://nuphy.com?sca_ref=1384450.HkCkaUA2fv" discount="5% OFF" code="KEVIN5"  external>
            <LinkItem image={props.data.nutype.childImageSharp.fluid} url="https://nuphy.com?sca_ref=1384450.HkCkaUA2fv" external>NuType F1 Keyboard - White switches</LinkItem>
          </SingleLinkContainer>
          <SingleLinkContainer label="LG" url="https://amzn.to/3wmuikd" external>
            <LinkItem image={props.data.monitor.childImageSharp.fluid} url="https://amzn.to/3wmuikd" external>27" 4K UHD Monitor</LinkItem>
          </SingleLinkContainer>
        </LinksContainer>
        <LinksContainer>
          <SingleLinkContainer label="Neewer" url="https://kevinng.ca/go/neewer" discount="15% OFF" code="KEVIN" external>
            <LinkItem image={props.data.neewer.childImageSharp.fluid} url="https://kevinng.ca/go/neewer-tabletop-LED-light" external>Tabletop LED Lights</LinkItem>
          </SingleLinkContainer>
          <SingleLinkContainer label="Macally" url="https://kevinng.ca/go/macally" discount="20% OFF" code="KEVIN" external>
            <LinkItem image={props.data.macally.childImageSharp.fluid} url="https://kevinng.ca/go/macally-stand" external>Aluminum Laptop Stand</LinkItem>
          </SingleLinkContainer>
        </LinksContainer>
        <LinksContainer>
          <SingleLinkContainer label="Copaze" url="https://shop.copaze.com/" external>
            <LinkItem image={props.data.airDior.childImageSharp.fluid} url="https://shop.copaze.com/collections/air-jordan-1-rug/products/aj1-air-dior-rug" external>Air Dior floor mat</LinkItem>
          </SingleLinkContainer>
          <SingleLinkContainer label="Yeelight" url="https://www.aliexpress.com/item/1005002238265590.html" external>
            <LinkItem image={props.data.yeelight.childImageSharp.fluid} url="https://www.aliexpress.com/item/1005002238265590.html" external>LED Screen Light Bar</LinkItem>
          </SingleLinkContainer>
        </LinksContainer>
        <LinksContainer>
          <SingleLinkContainer label="iVANKY" url="https://amzn.to/3hCCqZO" external>
            <LinkItem image={props.data.ivanky.childImageSharp.fluid} url="https://amzn.to/3hCCqZO" external>Docking Station</LinkItem>
          </SingleLinkContainer>
        </LinksContainer>
        <p css={styles.disclaimer}>I earn a commission from some of the links here at no extra cost to you. This goes to supporting me and the content I create. Thank you!</p>
    </Layout>
)}

export default LinksPage

export const pageQuery = graphql`
  query {
    profile: file(relativePath: { eq: "profile.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    nomadHub: file(relativePath: { eq: "nomad-hub.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    nomadMini: file(relativePath: { eq: "nomad-mini.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    nomadSleeve: file(relativePath: { eq: "nomad-sleeve.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    nomadWallet: file(relativePath: { eq: "nomad-wallet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    nomadCases: file(relativePath: { eq: "nomad-cases.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    orbitkeyKeyOrganiser: file(relativePath: { eq: "orbitkey-keyorganizer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    orbitkeyRing: file(relativePath: { eq: "orbitkey-ring.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    orbitkeyDeskMat: file(relativePath: { eq: "orbitkey-desk-mat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    monitor: file(relativePath: { eq: "lg-monitor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    ivanky: file(relativePath: { eq: "ivanky.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    neewer: file(relativePath: { eq: "neewer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    macally: file(relativePath: { eq: "macally-stand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    airDior: file(relativePath: { eq: "air-dior.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    nutype: file(relativePath: { eq: "nutype.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    yeelight: file(relativePath: { eq: "yeelight.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    secretlab: file(relativePath: { eq: "secret-lab.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    wrapd: file(relativePath: { eq: "wrapd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`;
