import { css } from '@emotion/core';

export const linksContainer = css`
  width: 100%;
  margin-bottom: 20px;

  h3 {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    letter-spacing: -1px;
    text-transform: uppercase;
  }

  p {
    line-height: 1.2;
  }

  @media(max-width: 480px) {
    margin-bottom: 16px;
  }
`

export const linksGrid = css`
  max-width: 848px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px 24px;

  @media(max-width: 480px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const linkHeader = css`
  margin-bottom: 4px;
  display: block;
`

export const link = css`
  display: inline-block;
  margin: 0;
  font-family: 'Rubik';
  grid-area: link;

  @media(max-width: 768px) {
    &::after {
        content: '›';
        display: inline;
        margin-left: 8px;
    }
  }
`

export const discount = css`
  flex-shrink: 0;

  p {
    display: inline-block;
    padding: 0 6px;
    text-align: center;

    &:first-child {
      color: #dcdcdc;
      background: #2063cc;
      border-radius: 2px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      color: #dcdcdc;
      background: #2f2f2f;
      border-radius: 2px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      
      @media (prefers-color-scheme: dark) {
        color: #2f2f2f;
        background: #dcdcdc;
      }
    }
  }
`
