import { css } from '@emotion/core';

export const linkContainer = css`
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  background: linear-gradient(90deg, #2063cc, #3884ff);
  border-radius: 2px;
  border-bottom-right-radius: 0;
  line-height: 1;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background: linear-gradient(45deg, transparent 50%, #dcdcdc 50%);
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%) rotate(45deg);
  }
  

  h3 {
    display: inline-block;
    font-size: 20px;
    letter-spacing: -1px;
    text-transform: uppercase;
  }

  ul {
    li {
      list-style-type: none;
    }
  }

  p {
    line-height: 1.4;
  }
`

export const link = css`
    display: inline-block;
    margin: 0;
    font-family: 'Rubik';
    background-color: unset;
    background-image: unset;
    background-size: 100%;
    -webkit-background-clip: unset;
    -moz-background-clip: unset;
    -webkit-text-fill-color: unset; 
    -moz-text-fill-color: unset;
    color: #dcdcdc;

    @media(max-width: 768px) {
      &::after {
          content: none;
      }
    }
`