import React from "react"

import * as styles from "./styles"

const BannerLinkContainer = ({url, label, external}) => 
{
    return (
    <div css={styles.linkContainer}>
        <a css={styles.link} href={url} target={external ? "_blank" : "_self"} rel="noreferrer">
            <h3>{label}</h3>
        </a>
    </div>
)}

export default BannerLinkContainer